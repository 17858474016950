import { useEffect } from "react"
import "./Privacy-Policy.css"

export default function PrivacyPolicy() {

    useEffect(() => {
        window.scrollTo(0, 0)

      }, [])

    return (
        <div>
            <div className="about-header-title">
                <h1>Política de privacidad</h1>
            </div>
            <div className="privacy-policy-wrapper">
                <p>Virág Martinkó te informa sobre su Política de Privacidad respecto del tratamiento y 
                    protección de los datos de carácter personal de los usuarios y clientes que 
                    puedan ser recabados por la navegación o contratación de servicios a través 
                    del sitio Web https://www.florpasea.com.
                </p>
                <p>
                En este sentido, el Titular garantiza el cumplimiento de la normativa vigente en materia 
                de protección de datos personales, reflejada en la Ley Orgánica 3/2018, de 5 de diciembre, 
                de Protección de Datos Personales y de Garantía de Derechos Digitales (LOPD GDD). Cumple 
                también con el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo de 27 de abril 
                de 2016 relativo a la protección de las personas físicas (RGPD).  
                </p>
                <p>
                El uso de sitio Web implica la aceptación de esta Política de Privacidad así como las 
                condiciones incluidas en el Aviso Legal.
                </p>
                <h2>Identidad del responsable</h2>
                <ul>
                    <li>Titular: Virág Martinkó</li>
                    <li>NIF: Y9717503V</li>
                    <li>Domicilio: Bernuy de Porreros, Castilla y León, España.</li>
                    <li>Código postal: 40190</li>
                    <li>Sitio Web: https://www.florpasea.com</li>
                </ul>
                <h2>Principios aplicados en el tratamiento de datos</h2>
                <p>En el tratamiento de tus datos personales, el Titular aplicará los siguientes principios que 
                    se ajustan a las exigencias del nuevo reglamento europeo de protección de datos:
                </p>
                <ul>
                    <li>Principio de licitud, lealtad y transparencia: El Titular siempre requerirá el consentimiento 
                        para el tratamiento de tus datos personales que puede ser para uno o varios fines específicos 
                        sobre los que te informará previamente con absoluta transparencia.
                    </li>
                    <li>
                        Principio de minimización de datos: El Titular te solicitará solo los datos estrictamente 
                        necesarios para el fin o los fines que los solicita.
                    </li>
                    <li>
                    Principio de limitación del plazo de conservación: Los datos se mantendrán durante el tiempo 
                    estrictamente necesario para el fin o los fines del tratamiento.
                    El Titular te informará del plazo de conservación correspondiente según la finalidad. En el 
                    caso de suscripciones, el Titular revisará periódicamente las listas y eliminará aquellos 
                    registros inactivos durante un tiempo considerable.
                    </li>
                    <li>
                    Principio de integridad y confidencialidad: Tus datos serán tratados de tal manera que su seguridad, 
                    confidencialidad e integridad esté garantizada. Debes saber que el Titular toma las precauciones 
                    necesarias para evitar el acceso no autorizado o uso indebido de los datos de sus usuarios por 
                    parte de terceros.
                    </li>
                </ul>
                <h2>Obtención de datos personales</h2>
                <p>Para navegar por https://www.florpasea.com no es necesario que facilites ningún dato personal. 
                    Los casos en los que sí proporcionas tus datos personales son los siguientes:
                </p>
                <ul>
                    <li>Al contactar a través de los formularios de contacto o enviar un correo electrónico.</li>
                </ul>
                <h2>Tus derechos</h2>
                <p>El Titular te informa que sobre tus datos personales tienes derecho a:</p>
                <ul>
                    <li>Solicitar el acceso a los datos almacenados.</li>
                    <li>Solicitar una rectificación o la cancelación.</li>
                    <li>Solicitar la limitación de su tratamiento.</li>
                    <li>Oponerte al tratamiento.</li>
                    <li>Solicitar la portabilidad de tus datos.</li>
                </ul>
                <p>
                El ejercicio de estos derechos es personal y por tanto debe ser ejercido directamente por el interesado, 
                solicitándolo directamente al Titular, lo que significa que cualquier cliente, suscriptor o colaborador 
                que haya facilitado sus datos en algún momento puede dirigirse al Titular y pedir información sobre los 
                datos que tiene almacenados y cómo los ha obtenido, solicitar la rectificación de los mismos, solicitar 
                la portabilidad de sus datos personales, oponerse al tratamiento, limitar su uso o solicitar la cancelación 
                de esos datos en los ficheros del Titular.
                </p>
                <p>
                Para ejercitar tus derechos de acceso, rectificación, cancelación, portabilidad y oposición tienes que enviar 
                un correo electrónico a flor@florpasea.com junto con la prueba válida en derecho como una fotocopia del D.N.I. o equivalente.
                </p>
                <p>
                Tienes derecho a la tutela judicial efectiva y a presentar una reclamación ante la autoridad de control, en este caso, la Agencia 
                Española de Protección de Datos, si consideras que el tratamiento de datos personales que te conciernen infringe el Reglamento.
                </p>
                <h2>Finalidad del tratamiento de datos personales</h2>
                <p>
                Cuando te conectas al sitio Web para mandar un correo al Titular estás facilitando información de carácter personal de la que el 
                responsable es el Titular. Esta información puede incluir datos de carácter personal como pueden ser tu dirección IP, nombre y apellidos, 
                dirección física, dirección de correo electrónico, número de teléfono, y otra información. Al facilitar esta información, das tu 
                consentimiento para que tu información sea recopilada, utilizada, gestionada y almacenada por el Titular, solo como se describe 
                en la presente Política de Privacidad.
                </p>
                <p>
                Los datos personales y la finalidad del tratamiento por parte del Titular es diferente según el sistema de captura de información:
                </p>
                <ul>
                    <li>Formularios de contacto: El Titular solicita datos personales entre los que pueden estar: Nombre y apellidos, dirección de 
                        correo electrónico y número de teléfono con la finalidad de responder a tus consultas.
                        Por ejemplo, el Titular utiliza esos datos para dar respuesta a tus mensajes, dudas, quejas, comentarios o inquietudes que puedas tener 
                        relativas a la información incluida en el sitio Web, los servicios que se prestan a través del sitio Web, el tratamiento de tus datos personales, 
                        cuestiones referentes a los textos legales incluidos en el sitio Web, así como cualquier otra consulta que puedas tener y que no esté sujeta a las 
                        condiciones del sitio Web o de la contratación.</li>
                </ul>
                <h2>Seguridad de los datos personales</h2>
                <p>
                Para proteger tus datos personales, el Titular toma todas las precauciones razonables y sigue las mejores prácticas de la industria para evitar su pérdida, 
                mal uso, acceso indebido, divulgación, alteración o destrucción de los mismos.
                </p>
                <p>
                El sitio Web está alojado en vercel.com. La seguridad de tus datos está garantizada, ya que toman todas las medidas de seguridad necesarias 
                para ello. Puedes consultar su política de privacidad para tener más información.
                </p>
                <h2>Contenido de otros sitios web</h2>
                <p>Las páginas de este sitio Web pueden incluir contenido incrustado (por ejemplo, vídeos, imágenes, artículos, etc.). El contenido incrustado de 
                    otras web se comporta exactamente de la misma manera que si hubieras visitado la otra web.</p>
                <p>Estos sitios Web pueden recopilar datos sobre ti, utilizar cookies, incrustar un código de 
                    seguimiento adicional de terceros, y supervisar tu interacción usando este código.</p>
                <h2>Política de Cookies</h2>
                <p>Esta página web no emplea cookies de seguimiento.</p>
                <h2>Legitimación para el tratamiento de datos</h2>
                <p>
                La base legal para el tratamiento de tus datos es: el consentimiento. Para contactar con el Titular tienes que aceptar la presente Política de Privacidad.
                </p>
                <h2>Categorías de datos personales</h2>
                <p>Las categorías de datos personales que trata el Titular son:</p>
                <ul>
                    <li>Datos identificativos.</li>
                </ul>
                <h2>Navegación web</h2>
                <p>Al navegar por https://www.florpasea.com se pueden recoger datos no identificativos, que pueden incluir, la dirección IP, 
                    geolocalización, un registro de cómo se utilizan los servicios y sitios, hábitos de navegación y otros datos que no pueden ser utilizados para identificarte.
                </p>
                <h2>Exactitud y veracidad de los datos personales</h2>
                <p>Te comprometes a que los datos facilitados al Titular sean correctos, completos, exactos y vigentes, así como a mantenerlos debidamente actualizados.</p>
                <p>Como Usuario del sitio Web eres el único responsable de la veracidad y corrección de los datos que remitas al sitio exonerando a el Titular de 
                    cualquier responsabilidad al respecto.
                </p>
                <h2>Aceptación y consentimiento</h2>
                <p>Como Usuario del sitio Web declaras haber sido informado de las condiciones sobre protección de datos de carácter personal, aceptas y consientes 
                    el tratamiento de los mismos por parte de el Titular en la forma y para las finalidades indicadas en esta Política de Privacidad.
                </p>
                <h2>Revocabilidad</h2>
                <p>Para ejercitar tus derechos de acceso, rectificación, cancelación, portabilidad y oposición tienes que enviar un correo electrónico a flor@florpasea.com 
                    junto con la prueba válida en derecho como una fotocopia del D.N.I. o equivalente.
                </p>
                <p>
                    El ejercicio de tus derechos no incluye ningún dato que el Titular esté obligado a conservar con fines administrativos, legales o de seguridad.
                </p>
                <h2>Cambios en la Política de Privacidad</h2>
                <p>El Titular se reserva el derecho a modificar la presente Política de Privacidad para adaptarla a novedades legislativas o jurisprudenciales, 
                    así como a prácticas de la industria.
                </p>
                <p>Estas políticas estarán vigentes hasta que sean modificadas por otras debidamente publicadas.</p>
            </div>
            

        </div>
    )
}