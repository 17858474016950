import { Helmet } from 'react-helmet';
import structuredData from '../StructuredData';

const StructuredData = () => {
  return (
    <div>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
    </div>
  );
};

export default StructuredData;
