import "./About.css"
import { useEffect } from "react"

export default function About() {

    useEffect(() => {
        window.scrollTo(0, 0)

      }, [])

    return (
        <>
        <div className="about-header-title">
             <h1>Quién soy yo</h1>
        </div>
        <div className="history-image-wrapper">
            <div className="history-image-left">
                <img src="/virag-about-me.webp" alt="Flor con su perro, Okker" className="square-image      "/>
                <div className="about-header-text-left responsive-width-no-image">
                    <p>
                    Hola! Me llamo Virág🌸, pero por el significado de mi nombre también me dicen Flor. 
                    Tengo 27 años y soy de Budapest, Hungría. Me formé en educación canina y peluquería canina, y
                    ofrezco servicios de guardería canina y paseos profesionales para perros en Segovia y alrededores. 
                    </p>
                    <p>
                    Tengo un perro adoptado de 10 años que se llama Okker. 
                    Además de pasar tiempo con él, me gusta estar en la naturaleza, practicar deportes, 
                    ver series, cocinar (y comer jaja), pero me interesa mucho aprender idiomas también. 
                    Hablo húngaro, español e inglés. 
                    </p>
                </div>
            </div>
        </div>
        <div className="history-image-wrapper">
            <div className="history-image-left reverse">
                <div className="about-header-text-left responsive-width-no-image">
                    <p>
                    Me encanta viajar. Aparte de España, he visitado Austria, Eslovaquia, Francia y Uruguay, 
                    y viví más de un año en Argentina. Ahí tuve la suerte de trabajar en una quinta 
                    como voluntaria ayudando a entrenar caballos y cuidar a otros animales como 
                    perros, gatos, gallinas y una vaca. También aprendí a manejar tractor, usar 
                    motosierra y darles inyecciones intramusculares a caballos.
                    </p>
                    <p>
                    Me mudé a España en 2022 con mi novio argentino y estoy muy 
                    emocionada por seguir mi proyecto perruno en este país.
                    </p>
                </div>
                <img src="/about-me-coll-2.webp" alt="Flor trabajando en una finca" className="img-medium responsive-width-no-image"/>
            </div>
        </div>
        </>
    )
}