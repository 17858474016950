import "../Navigation.css"
import { useState, useEffect } from "react"
import { Link } from "react-router-dom"

export default function LateralBar() {

    const [showBar, setShowBar] = useState(false)
    const [showAboutMe, setShowAboutMe] = useState(false)
    const [firstRender, setFirstRender] = useState(true)

    let [position, setPosition] = useState("");

    const changeHeight = () => {

        document.documentElement.scrollTop > 90 ? setPosition("navigation-position-fixed") : setPosition("");
    }

    useEffect(()=>{
        document.addEventListener("scroll", changeHeight)

        return () => {
            document.removeEventListener("scroll", changeHeight)
        }

    }, [])

    const scrollHome = () => {
        if (window.location.pathname === "/") {
            window.scrollTo(0, 0)
        }
    }

    return (
        <>
        <div className={"hamburguer-menu-wrapper " + position}>
            <div className="hamburguer-menu" onClick={()=>{setShowBar(!showBar); setFirstRender(false)}}>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <Link to="/"><img src="flor-logo-webp.webp" alt="website logo" onClick={scrollHome}/></Link>
        </div>
        <div className={firstRender ? "d-none" : showBar ? "lateral-navigation" : "hide-lateral-bar"}>
            <div className="lateral-navigation-elements">
                <div className="close-lateral-bar-wrapper">
                    <div className="close-lateral-bar" onClick={()=>setShowBar(false)}>
                        <span className="close-one"></span>
                        <span className="close-two"></span>
                    </div>
                </div>
                <div>
                    <ul>
                        <Link to="/" onClick={()=>setShowBar(false)}><li>Inicio</li></Link>
                        <li onClick={()=>setShowAboutMe(!showAboutMe)}>Sobre mí</li>
                        {showAboutMe &&
                            <ul className="lateral-navigation-submenu">
                                <Link to="/sobre-mi" onClick={()=>setShowBar(false)}><li>Quién soy yo</li></Link>
                                <Link to="/formacion" onClick={()=>setShowBar(false)}><li>Formación y experiencia profesional</li></Link>
                                <Link to="/okker-y-yo" onClick={()=>setShowBar(false)}><li>La historia de Okker y yo</li></Link>
                            </ul>
                        }
                        <Link to="/opiniones" onClick={()=>setShowBar(false)}><li>Clientes felices</li></Link>
                        <Link to="/servicios" onClick={()=>setShowBar(false)}><li>Servicios</li></Link>
                        <Link to="/preguntas" onClick={()=>setShowBar(false)}><li>Preguntas</li></Link>
                        <Link to="/contacto" onClick={()=>setShowBar(false)}><li>Contacto</li></Link>
                    </ul>
                </div>
            </div>

        </div>
        </>
    )
}