import {useState, useEffect} from "react";
import "./Navigation.css"
import TopNagivationBar from "./View/TopBar";
import LateralBar from "./View/LateralBar";


export default function NagivationBar() {
    
    const [lateralBar, setLateralBar] = useState(false)
    
    window.addEventListener("resize", ()=> {
        let windowSize = window.innerWidth;

        if (windowSize < 900) {
            setLateralBar(true)
        } else {
            setLateralBar(false)
        }
    })

    useEffect(()=> {
        const windowSize = window.innerWidth;
        if (windowSize < 900) {
            setLateralBar(true)
        } else {
            setLateralBar(false)
        }
    }, [])

    return (
        <>
        {
            lateralBar ?
            <LateralBar />
            :
            <TopNagivationBar />
        }
        </>
    )
}