import viragDini from "../img/viragydini.jpg"
import okkerPro from "../img/viragyokkerpro.jpg"
import { useEffect } from "react"

export default function Experience() {

    useEffect(() => {
        window.scrollTo(0, 0)

      }, [])

    return (
        <>
        <div className="about-header-title">
            <h1>Formación y experiencia profesional</h1>
        </div>
        <div className="history-image-wrapper">
            <div className="history-image-left">
                <img src={okkerPro} className="experience-image history-smaller-image" alt="Flor con su perro, Okker"/>
                <div className="about-header-text-left">
                    <p>
                    Arranqué en el mundo del adiestramiento canino en 2014 cuando adopté a mi primer 
                    perro, Okker. Después de terminar el curso básico e intermedio con él en la escuela 
                    canina Kőbányai Kutyasuli en Budapest, tuve la oportunidad de quedarme y 
                    participar en las clases como aprendiz y ayudante, y luego incorporarme al grupo 
                    de adiestradores y formar parte de ese equipo por varios años. Gracias a eso, aprendí 
                    un montón sobre el comportamiento y la educación de los perros y sobre el vínculo perro-dueño. 
                    </p>
                    <p>
                    Durante los años probamos varios tipos de adiestramiento y diversos deportes con Oki 
                    (método tradicional, Mirror Method, Do As I Do, canicross, discdog, agility, SUPdog, hiking, mantrailing, etc).
                    </p>
                    <p>
                    En 2019 completé el curso de peluquería canina (de 320 horas) de Tapis Oktatási Központ donde 
                    obtuve aún más información sobre el cuidado profesional de los perros.
                    </p>
                </div>
            </div>
        </div>
        <div className="history-image-wrapper">
            <div className="history-image-left">
                <div className="about-header-text-left responsive-width-no-image">
                    <p>
                    En 2020 me mudé a Argentina y seguí estudiando de forma remota. Asistí a varias clases 
                    online organizadas por Patita Patita Adiestramiento:
                    </p>
                    <ul className="text-list">
                        <li>Taller de RCP y primeros auxilios en perros y gatos (13 de septiembre de 2020)</li>
                        <li>Curso regular de etología y solución de problemas comportamentales en perros (de 24 horas, 14 de septiembre de 2020)</li>
                        <li>Masterclass de educación y comunicación: top 10 de claves para su educación (20 de septiembre de 2020)</li>
                        <li>Masterclass de educación del cachorro (4 de octubre 2020)</li>
                        <li>Masterclass especial de educación: tirones de correa, ladrido con el timbre y ansiedad con la comida (22 de noviembre 2020)</li>
                        <li>Masterclass de perros y el agua (5 de diciembre 2020)</li>
                        <li>Masterclass de embarazo, niños y perros (1 de febrero 2021)</li>
                        <li>Masterclass de viajes, seguridad y vacaciones (13 de marzo 2021)</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="history-image-wrapper">
            <div className="history-image-left">
                <img src={viragDini} className="experience-image" alt="Flor con un perro rescatado"/>
                <div className="about-header-text-left">
                    <p>
                    Viviendo en Argentina recibí en mi casa a dos perros rescatados muy especiales (Fito y Rocco), 
                    les ayudé a recuperarse física y emocionalmente, empecé a educarlos y los preparé para una nueva vida 
                    con sus familias definitivas.
                    </p>
                    <p>
                    Después de volver a Hungría, decidí arriesgarme y seguir mis sueños: desde agosto de 2021 me dedico 
                    a pasear y cuidar perros a tiempo completo ayudándoles a vivir una vida más divertida y más equilibrada.
                    </p>
                    <p>
                    Como estoy convencida de que uno siempre puede aprender algo más, en febrero de 2023 
                    completé el Curso Inicial de Adiestramiento y Educación Canina (de 90 horas) de DOS Adiestramiento 
                    para tener aún más conocimiento sobre lo que tanto me gusta y me apasiona.
                    </p>
                </div>
            </div>
        </div>
        </>
    )
}