import "./PromoItem.css";
import { Link } from "react-router-dom";
import { FiXCircle } from "react-icons/fi";
import { useState } from "react";

export default function PromoItem() {

    const [display, setDisplay] = useState(false)

    return(
        
        <div className={`promo-item-container ${display && "d-none"}`}>
            <Link to="/contacto">
                <p>
                    Haz click aquí y pide una cita para la evaluación previa GRATUITA.
                </p>
            </Link>
            <div className="promo-item-close-button" onClick={()=>setDisplay(true)}>
                <FiXCircle />
            </div>
        </div>
        
          )
}