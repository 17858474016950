const structuredData = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    "name": "Flor Pasea",
    "description": "Guardería canina y paseos profesionales en Segovia y alrededores.",
    "url": "https://www.florpasea.com",
    "logo": "https://www.florpasea.com/flor-logo-webp.webp",
    "address": {
      "@type": "PostalAddress",
      "addressLocality": "Bernuy de Porreros",
      "addressRegion": "Segovia",
      "postalCode": "40190",
      "addressCountry": "España"
    },
    "paymentAccepted": "Efectivo, Bizum",
    "contactPoint": {
      "@type": "ContactPoint",
      "email": "flor@florpasea.com",
      "contactType": "Flor"
    },
    "hasOfferCatalog": {
      "@type": "OfferCatalog",
      "name": "Guardería Canina y Paseos Profesionales",
      "itemListElement": [
        {
          "@type": "Offer",
          "itemOffered": {
            "@type": "Service",
            "name": "Paseo Sanitario",
            "description": "Paseo por el barrio para olfatear y hacer sus necesidades.",
          }
        },
        {
            "@type": "Offer",
            "itemOffered": {
              "@type": "Service",
              "name": "Paseo Urbano",
              "description": "Paseo por el barrio con ejercicio físico y mental, juego, olfateo y necesidades.",
            }
          },
          {
            "@type": "Offer",
            "itemOffered": {
              "@type": "Service",
              "name": "Excursión en la Naturaleza",
              "description": "Paseo en la naturaleza con ejercicio físico y mental, juego, momento de relajación y más.",
            }
          },
      ]
    },
  }

export default structuredData;