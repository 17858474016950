import { Card, CardBody, Image, Stack, Heading, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import "./Card.css"

export default function FeedbackCard({title, body, imageLink}) {

    const [trimmed, setTrimmed] = useState(false)
    const [trimmedText, setTrimmedText] = useState("")
    const [seeMore, setSeeMore] = useState(false)

    useEffect(()=> {
        if (body.length > 300) {
            setTrimmedText(body.slice(0, 300))
            setTrimmed(true)
            setSeeMore(true)
        }
    }, [body])


    return (
            <Card
            direction={{ base: 'column', sm: 'row' }}
            overflow='hidden'
            variant='outline'
            style={{width: "100%"}}
            >
                <Image
                    objectFit='cover'
                    maxW={{ base: '100%', sm: '200px' }}
                    src={imageLink}
                    alt={`Foto de ${title}`}
                    className="responsive-card-image"
                />

                <Stack>
                    <CardBody>
                    <Heading size='md'>{title}</Heading>

                    <Text py='2'>
                        {
                        trimmed ?
                        trimmedText
                        :
                        body
                        
                        }
                        {
                            seeMore && <>
                            
                            {trimmed && <span>... </span>}
                            <span 
                            onClick={()=>setTrimmed(!trimmed)}
                            style={{color: '#2E86C1', fontWeight: 400, cursor: "pointer"}}>
                                {trimmed ? "ver más" : " Ver menos"}
                            </span>
                            
                            </>
                        }
                    </Text>
                    </CardBody>

                </Stack>
            </Card>

)}