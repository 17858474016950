import FeedbackCard from "../UIComponents/Card"
import "./Clients.css"
import { useEffect } from "react"
import { clientReviews } from "./ClientsDB"

export default function Clients() {

    useEffect(() => {
        window.scrollTo(0, 0)

      }, [])

    
    return (
        <div className="clients">
            <div className="about-header-title">
                <h1>Clientes felices</h1>
            </div>
            
            <div className="container">
                <div className="row" >
                    <div className="d-flex gap-5 flex-wrap justify-content-center">
                        <div className="feedback-masonry">
                            
                                {
                                    clientReviews.map((client, index)=>
                                        <FeedbackCard
                                         title={client.title}
                                         body={client.body}
                                         imageLink={client.imageLink} 
                                         key={index}
                                        />
                                    )
                                }
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}