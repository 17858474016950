import { useToast } from "@chakra-ui/react"
import { useEffect } from "react"

export default function Toast() {
    const toast = useToast()

    useEffect(()=>{

        toast({
            title: `Aviso importante`,
            description: 'Voy a estar de vacaciones del 12 de septiembre al 7 de octubre.',
            position: 'bottom-right',
            isClosable: true,
            duration: 99999,
            containerStyle: {
                width: '400px',
                maxWidth: '100%',
            },
        })

    }, [])


    return (
      <></>
    )
  }