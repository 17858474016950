import { NavLink } from 'react-router-dom';
import {useState} from "react";
import "../Navigation.css"

export default function TopNagivationBar() {
    
    const [isShown, setIsShown] = useState(false);

    return (
        <nav className="navigation-bar">
            <ul>
                <li>
                    <NavLink to="/">
                        <p>Inicio</p>
                    </NavLink>
                </li>
                <li onMouseLeave={()=> setIsShown(false)}>
                    <div onMouseEnter={()=> {setIsShown(true)}}>
                        <p>Sobre mí</p>
                    { isShown && (
                    <ul className="dropdown-virag" onMouseLeave={()=> setIsShown(false)}>
                        <li><NavLink to="/sobre-mi"><p>Quién soy yo</p></NavLink></li>
                        <li><NavLink to="/formacion"><p>Formación y experiencia profesional</p></NavLink></li>
                        <li><NavLink to="/okker-y-yo"><p>La historia de Okker y yo</p></NavLink></li>
                    </ul>
                    )
                    }
                    </div>
                </li>
                <li>
                    <NavLink to="/opiniones">
                        <p>Clientes felices</p>
                    </NavLink>
                </li>
            </ul>
            <div className="">
                <NavLink to="/">
                <img className="logo" src="/flor-logo-webp.webp" alt="" />
                </NavLink>
            </div>
            <ul>
                <li>
                    <NavLink to="/servicios">
                        <p>Servicios</p>
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/preguntas">
                        <p>Preguntas</p>
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/contacto">
                        <p>Contacto</p>
                    </NavLink>
                </li>
            </ul>
       </nav>
    )
}