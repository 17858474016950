import presente from "../img/okkerandfriends.jpg"
import babyOkker from "../img/okibb.jpg"
import { useEffect } from "react"

export default function History() {

    useEffect(() => {
        window.scrollTo(0, 0)

      }, [])

    return (
        <>
        <div className="about-header-title about-header-title-responsive-width">
            <h1>La historia de Okker y yo</h1>
        </div>
        <div className="history-image-wrapper mb-0-responsive">
            <div className="history-image-right">
                <img className="present-picture" src={babyOkker} alt="Foto de varios perros en un bosque" />
                <div className="about-header-text-left">
                    <h2>Los comienzos</h2>
                    <p>
                    La vida de Okker no empezó de la mejor forma. Él y sus 8 hermanos eran cachorros no deseados. 
                    Ni bien nacieron, los separaron de la madre, los metieron en un saco de plástico y los tiraron 
                    a la calle. Cuando un paseador los encontró, todavía tenían el cordón umbilical colgando. 
                    Tenían como máximo 3 días de vida. Al principio los alimentaron con mamadera y cuando empezaron a 
                    comer alimento sólido los trasladaron a un refugio.
                    </p>
                    <p>
                    A Oki lo adoptamos cuando tenía 5 meses. Eso significa que pasó su etapa sensible sin ningún tipo de 
                    ejemplo a seguir y con cero socialización. Nunca tuvo la oportunidad de estar con su mamá y aprender 
                    de ella sobre comunicación, juego o independencia. Tampoco le pusieron límites nunca, excepto ese hombre 
                    en el refugio que les pegaba.
                    </p>
                </div>
            </div>
        </div>
        
        <div className="history-image-wrapper">
            <div className="history-image-right">
                <div className="about-header-text-left responsive-width-no-image">
                    <p>
                    Oki llegó a casa con muchos miedos e inseguridades. Todo era nuevo para él. Le daban miedo los coches, 
                    los ruidos y la gente. Cuando nos encontrábamos con un hombre, Oki se hacía pis encima. Lo llevé a clases 
                    de obediencia y empecé a educarlo como podía, pero él tenía cada vez más problemas de conducta.
                    </p>
                    <p>
                    Nos gruñía cuando queríamos sacarle su hueso. No quería dejar entrar a la gente que venía de 
                    visita. Les gruñía y hasta les agarraba el pantalón/el tobillo/la mano. En la calle era muy 
                    desconfiado con ciertas personas. Les gruñía, les ladraba y los seguía.
                    </p>
                    <p>
                    Cuando Oki empezó a crecer, su relación con los perros también empeoró. Primero solo se 
                    peleaba con los machos, después con las hembras también y al final ya había atacado a casi 
                    todos los perros del barrio, incluso a un cachorro. Nunca llegó a causarles heridas graves, 
                    pero eso fue cuestión de suerte.
                    </p>
                    <p>
                    Yo me sentía fatal. Sentía culpa, enojo, miedo, decepción, impotencia. Me sentía una dueña 
                    horrible. Hice todo lo que pude para "reparar" a Oki, pero nada me funcionó. No lo podía 
                    controlar. Era mi primer perro y yo no tenía el conocimiento necesario para ayudarle.
                    </p>
                </div>
            </div>
        </div>
        <div className="history-image-wrapper">
            <div className="history-image-right">
                <div className="about-header-text-left responsive-width-no-image">
                    <h2>Aprendiendo</h2>
                    <p>
                    Cuando me di cuenta de que no podía solucionar el problema sola, decidí cambiar de escuela de 
                    perros. En la nueva escuela no solo nos enseñaron ejercicios de obediencia, sino también el 
                    cómo y el porqué de las cosas.
                    </p>
                    <p>
                    Primero tuvimos una consulta con una especialista en problemas de conducta y después pudimos 
                    inscribirnos en los cursos grupales. En esas clases aprendí mucho sobre perros. De sus necesidades, 
                    de su comunicación y de cómo aprenden. Me enseñaron a reconocer las señales de Oki y saber qué 
                    quiere, cómo se siente y qué puedo hacer para ayudarle.
                    </p>
                    <p>
                    Practicamos cientos de horas, aprobamos dos exámenes y probamos diferentes deportes 
                    caninos. Yo empecé a formarme para ser adiestradora, y pasé casi todos los fines 
                    de semana durante 4 años aprendiendo y después trabajando en la escuela de perros.
                    </p>
                    <p>
                    No me acuerdo de cada paso que dimos con Oki, pero sé que lo que más nos ayudó no fue 
                    un método o un ejercicio, sino la ayuda de la gente que nos rodeaba. La gente que creía 
                    en nosotros, que nos motivaba, que nos invitaba a practicar y nos daba fuerza cuando 
                    cometíamos errores. Esa gente que nos acompañó en el camino y no nos dejó renunciar.
                    </p>
                </div>
            </div>
        </div>
        <div className="history-image-wrapper">
            <div className="history-image-right">
                <img className="present-picture" src={presente} alt="Foto de varios perros en un bosque" />
                <div className="about-header-text-left">
                    <h2>El presente</h2> 
                    <p>
                    Gracias a la gente que nos apoyó y a todo el tiempo, dinero y energía que invertí en 
                    mejorar mi relación con mi perro, hoy estamos en un lugar mucho mejor. Oki cambió un 
                    montón y yo aprendí a entenderlo mejor. Ya lo conozco bastante bien y sé cómo puede 
                    reaccionar en diferentes situaciones. Conozco sus límites. Sé cuándo puedo darle más 
                    libertad y cuándo necesita más control o simplemente salir de la situación.
                    </p>
                    <p>
                    Ya podemos ir de excursión con otros perros educados. Con algunos Oki juega, 
                    con otros se ignoran mutuamente. Podemos pasear con la correa floja y sin correa también. 
                    Si nos cruzamos con otros perros, podemos seguir de largo sin conflicto. Oki a veces 
                    ni siquiera gira la cabeza para verlos. Hasta pudimos participar en demostraciones de obediencia 
                    y de exámenes en la escuela de perros.
                    </p>
                    <p>
                    En la calle Oki ya no tiene problema con la gente. Los acepta como parte del ambiente y 
                    no les presta mucha atención. Puedo viajar con él en transporte público y puedo llevarlo 
                    conmigo al centro comercial también. Ya se lleva bien con los miembros de nuestra familia 
                    y con los amigos más cercanos, y se acostumbra mucho más rápido a las personas nuevas también.
                    </p>
                    <p>
                    Siempre nos quedará algo en qué trabajar y Oki nunca será un perro "fácil". Pero gracias a eso, 
                    nunca dejaré de esforzarme para aprender y crecer como persona, como dueña y como profesional.
                    </p>
                </div>
            </div>
        </div>
        </>
    )
}