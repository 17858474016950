import { Link } from "react-router-dom"
import "./MainButton.css"

export default function MainButton({path, text}) {
    return(
        <Link to={path}>
            <div className="price-box-button">
                {text}
            </div>
        </Link>
    )
}